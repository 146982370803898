import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Solutions from "/src/components/pages/solutions/Solutions";
import SolutionsIntro from "/src/components/pages/solutions/SolutionsIntro";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";

import circuit2 from "/static/assets/animations/circuit/circuit01.json";

const IcsSecurity = ({data}) => {
    
    const {ics} = data;
    
        return (
            <Layout lang={ics.lang} seo={ics.SEO}>
                <LottieAnimation animationData={circuit2} left="true"/>
                <SolutionsIntro
                    title={ics.title}
                    subTitle={ics.subtitle}
                    description={ics.description}
                    button={ics.intro_button}
                    buttonStyle="one-identity-primary"
                />
                <Solutions
                    subTitle={ics.cards.title}
                    challangesCards={ics.cards.solution_card}
                    button={ics.solutions_button}
                    buttonStyle="one-identity-primary"
                />
            </Layout>
        )
}

export const query = graphql`
   query GetSingleIcsSecurity($locale: String) {
         ics: strapiIcsSecurities(lang: { eq: $locale }) {
          lang
          title
          subtitle
          description
          intro_button {
            id
            name
            link
          }
          cards {
            title
            solution_card {
              avatar {
                url
              }
              id
              title
              description
            }
          }
          solutions_button {
            id
            name
            link
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default IcsSecurity